// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-indigo-tsx": () => import("./../../../src/pages/indigo.tsx" /* webpackChunkName: "component---src-pages-indigo-tsx" */),
  "component---src-pages-industries-tsx": () => import("./../../../src/pages/industries.tsx" /* webpackChunkName: "component---src-pages-industries-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-airline-booking-engine-tsx": () => import("./../../../src/pages/products/airline-booking-engine.tsx" /* webpackChunkName: "component---src-pages-products-airline-booking-engine-tsx" */),
  "component---src-pages-products-business-travel-management-tsx": () => import("./../../../src/pages/products/business-travel-management.tsx" /* webpackChunkName: "component---src-pages-products-business-travel-management-tsx" */),
  "component---src-pages-products-travel-connector-tsx": () => import("./../../../src/pages/products/travel-connector.tsx" /* webpackChunkName: "component---src-pages-products-travel-connector-tsx" */),
  "component---src-pages-products-travel-reservation-system-tsx": () => import("./../../../src/pages/products/travel-reservation-system.tsx" /* webpackChunkName: "component---src-pages-products-travel-reservation-system-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blogs-tsx": () => import("./../../../src/templates/blogs.tsx" /* webpackChunkName: "component---src-templates-blogs-tsx" */)
}

